<template>
  <div v-frag>
    <background-check-notify
      :isOpen='isBackgroundCheck'
      :charity-name='selectedCharityName'
      @confirmRequest='applyForVolunteering'
      @closeDialog='isBackgroundCheck = false'
    />
    <v-alert
      v-model='alertOptions.show'
      :color='alertOptions.color'
      class='rounded-xl ml-5 mr-5'
      dark
      dense
      dismissible
      icon='mdi-check-circle-outline'
      prominent
      transition='scale-transition'
      width='98%'
    >
      <h2 :class="{'mt-2' : alertOptions.message}">{{ alertOptions.title }}</h2>
      <p class='mb-2' v-if='alertOptions.message'>{{ alertOptions.message }}</p>
    </v-alert>
    <v-card
      v-if="isModuleActive('volunteering')"
      class='ma-5 rounded-lg'
      elevation='3'
      max-width='100%'
    >
      <v-data-table
        :footer-props='{
            disablePagination: loadingOpportunities,
            disableItemsPerPage: loadingOpportunities
          }'
        :headers='opportunitiesHeaders'
        :items='opportunities'
        :loading='loadingOpportunities'
        :options.sync='options'
        :server-items-length='totalOpportunities'
        class='mx-6 mt-6'
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title class='headline'>
              {{ sections.open_volunteer_opportunities || 'Open Volunteer Opportunities' }}
            </v-toolbar-title>
          </v-toolbar>
        </template>
        <template #item.charityName={item}>
            <span
              class='text-decoration-underline showPointer'
              @click='goToCharityProfile(item)'
            >
              {{ item.charityName }}
            </span>
        </template>
        <template #item.time={item}>
          {{ item.timeStart }} - {{ item.timeEnd }}
        </template>
        <template #item.spots={item}>
          <div :class="item.color + '--text'">
            {{ item.availableSpots }} / {{ item.volunteersRequired }}
          </div>
        </template>
        <template #item.actions={item}>
          <v-row class='d-flex flex-row align-center justify-start'>
            <v-btn
              :loading='loadingConfirm && item.id === selectedCharity.id'
              :color="item.requested ? 'error' : 'primary' "
              class='px-2 ma-4 font-weight-bold white--text text-capitalize apply-btn'
              small
              @click='handler(item)'
            >
              <span v-if='!item.requested' class='px-2'> Apply </span>
              <span v-else class='mr-1 px-1'> Cancel </span>
            </v-btn>
            <v-btn
              class='outlined font-weight-bold white--text text-capitalize'
              color='success'
              small
              @click='openRequestInfoModal(item)'
            >
              + Info
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <insufficient-hours-dialog
      :isOpen='isInsufficientHoursDialogOpen'
      @closeDialog='closeDialog'
    />
    <opportunity-info-dialog
      :isOpen='isInfoDialogOpen'
      :selectedCharity='selectedCharity'
      @closeDialog='isInfoDialogOpen = false'
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import OpportunityInfoDialog from './OpportunityInfoDialog'
import InsufficientHoursDialog from './InsufficientHoursDialog'
import BackgroundCheckNotify from '@/components/dialogs/background-check-notify'

export default {
  name: 'VolunteerOpportunities',
  components: {
    InsufficientHoursDialog,
    OpportunityInfoDialog,
    BackgroundCheckNotify
  },
  data() {
    return {
      opportunities: [],
      loadingOpportunities: false,
      options: {},
      totalOpportunities: null,
      opportunitiesHeaders: [
        { text: 'Charity', align: 'start', value: 'charityName' },
        { text: 'Need', align: 'start', value: 'charityNeed' },
        { text: 'Date', align: 'start', value: 'date' },
        { text: 'Hs.', align: 'start', value: 'hours' },
        { text: 'Time', align: 'start', value: 'time' },
        { text: 'Address', align: 'start', value: 'address' },
        { text: 'Spots', align: false, sortable: false, value: 'spots' },
        { text: 'Actions', align: false, sortable: false, value: 'actions', width: '20%' }
      ],
      isInfoDialogOpen: false,
      selectedCharity: {},
      isInsufficientHoursDialogOpen: false,
      loadingConfirm: false,
      isBackgroundCheck: false,
      alertOptions: {
        show: false
      }
    }
  },
  computed: {
    ...mapState({
      opportunitiesByEmployee: state => state.employeeStore.opportunitiesByEmployee,
      sections: state => state.cmsStore.sections
    }),
    ...mapGetters('employeeStore', ['isModuleActive', 'getDaysOff']),
    selectedCharityName() {
      return this.selectedCharity.charityName
    }
  },
  watch: {
    options: {
      async handler() {
        await this.getOpportunities()
      },
      deep: true
    },
    opportunitiesByEmployee() {
      this.opportunities = this.opportunitiesByEmployee.data
    }
  },
  methods: {
    ...mapActions('employeeStore', [
      'getOpportunitiesByEmployee',
      'submitVolunteerRequest',
      'cancelVolunteerRequest',
      'getUpcomingVolunteering'
    ]),
    async getOpportunities() {
      this.loadingOpportunities = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const filters = {
        sortBy: sortBy['0'],
        sortDesc: sortDesc['0'],
        page,
        itemsPerPage
      }
      try {
        await Promise.allSettled([
          this.getOpportunitiesByEmployee(filters),
          this.getUpcomingVolunteering()
        ])
        this.totalOpportunities = this.opportunitiesByEmployee.total
        this.opportunities = this.opportunitiesByEmployee.data
      } catch (err) {
        this.loadingOpportunities = false
        console.log(err)
      }
      this.loadingOpportunities = false
    },
    async applyForVolunteering() {
      this.loadingConfirm = true
      try {
        await this.submitVolunteerRequest(this.selectedCharity.id)
        this.openAlert(true, 'Request Submitted', 'The volunteering request was made successfully')
        await this.getOpportunities()
      } catch (e) {
        this.openAlert(false, 'Something went wrong')
        console.log(e)
      }
      this.loadingConfirm = false
    },
    goToCharityProfile(item) {
      this.$store.dispatch('charity', item.charity.id)
      this.$router.push('/employee/charity-donations')
    },
    openRequestInfoModal(item) {
      this.selectedCharity = item
      this.isInfoDialogOpen = true
    },
    async applyHandler(item) {
      if (item.hours > this.getDaysOff.availableHours) {
        this.isInsufficientHoursDialogOpen = true
        return
      }
      this.selectedCharity = item
      item.backgroundCheck ? this.isBackgroundCheck = true : await this.applyForVolunteering()
    },
    async cancelHandler(item) {
      this.selectedCharity = item
      this.loadingConfirm = true
      try {
        await this.cancelVolunteerRequest(this.selectedCharity.id)
        this.openAlert(true, 'Canceled Successfully')
        await this.getOpportunities()
      } catch (e) {
        this.openAlert(false, 'Something went wrong')
        console.log(e)
      }
      this.loadingConfirm = false
    },
    closeDialog() {
      this.selectedCharity = {}
      this.isInsufficientHoursDialogOpen = false
    },
    async handler(item) {
      item.requested ? await this.cancelHandler(item) : await this.applyHandler(item)
    },
    openAlert(status, title, message) {
      if (status) {
        this.alertOptions = {
          show: true,
          color: 'success',
          title: title,
          message: message
        }
      } else {
        this.alertOptions = {
          show: true,
          color: 'error',
          title: title
        }
      }
      this.hideAlertMsg()
    },
    hideAlertMsg() {
      setTimeout(() => this.alertOptions.show = false, 4000)
    }
  }
}
</script>
