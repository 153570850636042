<template>
  <v-dialog v-model='isOpen' max-width='1500px' persistent scrollable>
    <v-card class='px-5' height='100%'>
      <v-data-table
        :headers='headers'
        :items='[selectedCharity]'
        class='mt-6 elevation-4'
        hide
        hide-default-footer
      ></v-data-table>
      <v-data-table
        :headers='description'
        :items='[selectedCharity]'
        class='mt-6 elevation-4'
        hide-default-footer
      ></v-data-table>
      <v-data-table
        v-show='charityInfo'
        :headers='upcomingVolunteeringContactInfo'
        :items='[selectedCharity]'
        class='mt-6 elevation-4'
        hide-default-footer
      ></v-data-table>
      <v-data-table
        :headers='upcomingVolunteeringOtherInfo'
        :items='[selectedCharity]'
        class='mt-6 elevation-4'
        hide-default-footer
      ></v-data-table>
      <v-card-actions class='d-flex direction-row justify-center'>
        <v-btn
          class='px-14 ma-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OpportunityInfoDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    selectedCharity: {
      type: Object
    }
  },
  data: () => ({
    headers: [],
    description: [],
    charityInfo: false,
    volunteeringHeaders: [
      {
        text: 'Charity',
        align: 'start',
        sortable: true,
        value: 'charityName',
        divider: true
      },
      {
        text: 'Need',
        align: 'start',
        sortable: true,
        value: 'charityNeed',
        divider: true
      },
      {
        text: 'Hours',
        align: 'start',
        sortable: true,
        value: 'hours',
        divider: true
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'date',
        divider: true
      },
      {
        text: 'Volunteering Address',
        align: 'start',
        sortable: true,
        value: 'address',
        divider: true
      },
      {
        text: 'Start Time',
        align: 'start',
        sortable: true,
        value: 'timeStart',
        divider: true
      },
      {
        text: 'End Time',
        align: 'start',
        sortable: true,
        value: 'timeEnd',
        divider: true
      }
    ],
    volunteeringDescription: [
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'description'
      }
    ],
    upcomingVolunteeringContactInfo: [
      {
        text: 'Who do I contact when I arrive?',
        align: 'start',
        sortable: false,
        value: 'contactName',
        divider: true
      },
      {
        text: 'Contact Phone',
        align: 'start',
        sortable: false,
        value: 'contactPhone',
        divider: true
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'contactEmail',
        divider: true
      }
    ],
    upcomingVolunteeringOtherInfo: [
      {
        text: 'What do I wear/bring?',
        align: 'start',
        sortable: false,
        value: 'specialAttire',
        divider: true
      },
      {
        text: 'Where do I park/enter?',
        align: 'start',
        sortable: false,
        value: 'entryInstructions',
        divider: true
      },
      {
        text: 'Other important information',
        align: 'start',
        sortable: false,
        value: 'otherDetails',
        divider: true
      }
    ]
  }),
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.charityInfo = true
        this.headers = this.volunteeringHeaders
        this.description = this.volunteeringDescription
      }
    }
  }
}
</script>

<style scoped>

</style>