<template>
	<div class="text-center">
		<v-dialog v-model="isOpen" width="500" persistent>
			<v-card>
				<v-card-text class="px-5 py-4">
					{{ charityName || 'Selected charities' }} requires a background check for this opportunity.
					Please confirm you’d like to proceed with booking this volunteer opportunity.
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions class="justify-space-between">
					<v-btn color="primary" outlined @click="closeDialog"> cancel </v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" outlined @click="proceedProcess"> proceed </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		isOpen: {
			type: Boolean,
			required: true,
			default: false,
		},
		charityName: {
			type: String,
			default: '',
		},
	},
	methods: {
		closeDialog() {
			this.$emit('closeDialog')
		},
		proceedProcess() {
			this.$emit('confirmRequest')
			this.closeDialog()
		},
	},
}
</script>

<style></style>
