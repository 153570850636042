<template>
  <div v-frag>
    <employee-volunteering-table/>
    <volunteer-opportunities/>
    <custom-alert/>
    <time-volunteering/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TimeVolunteering from './components/TimeVolunteering'
import VolunteerOpportunities from './components/VolunteerOpportunities'
import CustomAlert from './components/CustomAlert'
import EmployeeVolunteeringTable from './EmployeeVolunteeringTable'

export default {
  name: 'CharityInvolvementVolunteering',
  components: { EmployeeVolunteeringTable, CustomAlert, VolunteerOpportunities, TimeVolunteering },
  async created() {
    await this.getEmployeeId()
  },
  methods: {
    ...mapActions({
      getEmployeeId: 'employeeStore/getEmployeeId'
    })
  }
}
</script>

