<template>
  <v-card
    :loading='isLoading'
    class='ma-5 rounded-lg'
    elevation='3'
  >
    <v-card-title class='justify-center pt-5 pb-4 headline'>
      {{ sections.time_volunteering || 'Time Volunteering in the Community' }}
    </v-card-title>
    <v-row v-if="isModuleActive('volunteering')" class='mb-6'>
      <v-col>
        <div class='d-flex flex-column align-center justify-center'>
          <p class='font-weight-bold'>Total Annual Hours:
            <span class='primary--text'>{{ getDaysOff.annualHours }}</span></p>
          <p class='font-weight-bold'>Total Remaining Hours:
            <span class='primary--text'>{{ getDaysOff.availableHours }}</span></p>
        </div>
      </v-col>
    </v-row>
    <table class='elevation-3 d-block'>
      <v-simple-table class='rounded-pill'>
        <thead>
        <tr>
          <th class='text-left'></th>
          <th class='text-left'>Days</th>
          <th class='text-left'>Hours</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="isModuleActive('voting')">
          <td class='py-6 font-weight-bold'>Total Voting Time Off</td>
          <td>{{ getDaysOff.votingDaysOff }}</td>
          <td>{{ getDaysOff.votingHoursOff }}</td>
        </tr>
        <tr v-if="isModuleActive('volunteering')">
          <td class='py-6 font-weight-bold'>
            Total Volunteering Time Off
          </td>
          <td>{{ getDaysOff.volunteeringDays }}</td>
          <td>{{ getDaysOff.volunteeringHours }}</td>
        </tr>
        <tr v-if="isModuleActive('volunteering')">
          <td class='py-6 font-weight-bold'>
            Total Non-Partner Volunteering
          </td>
          <td>{{ getDaysOff.nonPartnerVolunteeringDays }}</td>
          <td>{{ getDaysOff.nonPartnerVolunteeringHours }}</td>
        </tr>
        <tr class='grey white--text'>
          <td class='py-6 font-weight-bold'>TOTAL</td>
          <td>{{ getDaysOff.daysTotal }}</td>
          <td>{{ getDaysOff.hoursTotal }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </table>

    <v-card
      v-if="isModuleActive('donations')"
      class=' mt-10 pt-6 pb-2 px-6 d-flex flex-row align-center justify-space-between'
      flat
    >
      <h3 class='ml-4 font-weight-bold'>Total Donations</h3>
      <h2 class='mr-10 success--text font-weight-bold'>
        ${{ employeeProfile.totalDonations }}
      </h2>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'TimeVolunteering',
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      employeeProfile: state => state.employeeStore.employeeProfile,
      sections: state => state.cmsStore.sections
    }),
    ...mapGetters('employeeStore', ['getDaysOff', 'isModuleActive'])
  },
  async created() {
    this.isLoading = true
    await Promise.allSettled([
      this.getModules(),
      this.getEmployeeProfile()
    ])
    this.isLoading = false
  },
  methods: {
    ...mapActions('employeeStore', ['getModules', 'getEmployeeProfile'])
  }
}
</script>
