<template>
  <v-dialog v-model='isOpen' :persistent='true' max-width='600px'>
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='error' large left>
          mdi-alert
        </v-icon>
        Insufficient Hours
      </v-card-title>
      <v-card-subtitle>
        <b>
          You don't have enough hours to apply, please contact your
          superior in charge to require participation.
        </b>
      </v-card-subtitle>
      <v-card-actions class='align-self-center mt-n2'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InsufficientHoursDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>