<template>
  <div>
    <v-card class='ma-5 pa-5 rounded-lg' elevation='3' max-width='100%'>
      <v-card-title class='headline'>
        {{ sections.volunteering || 'Volunteering' }}
      </v-card-title>
      <v-btn-toggle
        v-model='selectedBtn'
        class='d-flex flex-row justify-center'
        color='primary accent-4'
        group
        mandatory
      >
        <v-btn
          text
          value='upcoming-volunteering'
          @click='getUpcoming'
        >
          Upcoming
        </v-btn>
        <v-btn
          text
          value='previous'
          @click='getPrevious'
        >
          Previous
        </v-btn>
      </v-btn-toggle>
      <v-data-table
        :headers='tableHeaders'
        :items='tableItems'
        :loading='loadingTable'
        no-data-text='You currently have no upcoming volunteer opportunities scheduled.'
        flat
        hide-default-footer
      >
        <template #item.approvedBy={item}>
          <span
            v-if="item.approvedBy === 'Pending'"
            class='text-decoration-underline error--text'
          >
            {{ item.approvedBy }}
          </span>
          <span
            v-else
            class='text-decoration-underline'
          >
            {{ item.approvedBy.firstName }}
          </span>
        </template>
        <template #item.verified={item}>
          <span v-if='item.verified'> Yes </span>
          <span v-else> - </span>
        </template>
        <template #item.actions={item}>
          <v-btn
            :loading='loadingCancel && item.id === volunteeringToCancel.id'
            class='outlined font-weight-bold white--text text-capitalize mr-2'
            color='error'
            small
            @click='cancelHandler(item)'
          >
            Cancel
          </v-btn>
          <v-btn
            class='outlined font-weight-bold white--text text-capitalize'
            color='success'
            small
            @click='showUpcomingVolunteeringDialog(item)'
          >
            + Info
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <upcoming-volunteering-dialog
      :isOpen='isUpcomingVolunteeringDialogOpen'
      :selectedVolunteering='selectedUpcomingVolunteering'
      @closeDialog='isUpcomingVolunteeringDialogOpen = false'
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UpcomingVolunteeringDialog from './components/UpcomingVolunteeringDialog'

export default {
  name: 'EmployeeVolunteeringTable',
  components: { UpcomingVolunteeringDialog },
  data() {
    return {
      upcomingVolunteeringHeaders: [
        { text: 'Charity', align: 'start', value: 'charity' },
        { text: 'Need', align: 'start', value: 'need' },
        { text: 'Date', align: 'start', value: 'date' },
        { text: 'Start Time', align: 'start', value: 'startTime' },
        { text: 'Hours', align: 'start', value: 'hours' },
        { text: 'Day of Service Address', align: 'center', value: 'address' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      volunteeringHeaders: [
        { text: 'Charity', align: 'center', value: 'charity.name' },
        { text: 'Date', align: 'center', value: 'date' },
        { text: 'Hours', align: 'center', value: 'hours' },
        { text: 'Acknowledged By', align: 'center', value: 'approvedBy' },
        { text: 'Verified By Charity', align: 'center', value: 'verified' },
        { text: 'Leave', align: 'center', value: 'leave' }
      ],
      loadingCancel: false,
      tableItems: [],
      tableHeaders: [],
      loadingTable: false,
      selectedBtn: null,
      volunteeringToCancel: {},
      isUpcomingVolunteeringDialogOpen: false,
      selectedUpcomingVolunteering: {}
    }
  },
  computed: {
    ...mapState({
      sections: state => state.cmsStore.sections,
      upcomingVolunteering: state => state.employeeStore.upcomingVolunteering,
      previousVolunteering: (state) => state.employeeStore.previousVolunteering
    })
  },
  // async created() {
  //   await this.getUpcoming()
  // },
  methods: {
    ...mapActions({
      getUpcomingVolunteering: 'employeeStore/getUpcomingVolunteering',
      getPreviousVolunteering: 'employeeStore/getPreviousVolunteering',
      cancelVolunteerRequest: 'employeeStore/cancelVolunteerRequest',
      getOpportunitiesByEmployee: 'employeeStore/getOpportunitiesByEmployee'
    }),
    async getUpcoming() {
      this.loadingTable = true
      this.tableItems = []
      this.tableHeaders = this.upcomingVolunteeringHeaders
      await this.getUpcomingVolunteering()
      this.tableItems = this.upcomingVolunteering
      this.loadingTable = false
    },
    async getPrevious() {
      this.loadingTable = true
      this.tableItems = []
      this.tableHeaders = this.volunteeringHeaders
      await this.getPreviousVolunteering()
      this.tableItems = this.previousVolunteering
      this.loadingTable = false
    },
    async cancelHandler(item) {
      this.volunteeringToCancel = item
      this.loadingCancel = true
      try {
        this.loadingTable = true
        await this.cancelVolunteerRequest(this.volunteeringToCancel.id)
        await this.getUpcoming()
        const filters = {
          page: 1,
          itemsPerPage: 10,
          search: ''
        }
        await this.getOpportunitiesByEmployee(filters)
        this.tableItems = this.upcomingVolunteering
        this.loadingTable = false
        this.openAlert(true, 'Canceled Successfully')
      } catch (e) {
        this.openAlert(false, 'Something went wrong')
      }
      this.loadingCancel = false
    },
    openAlert(status, title) {
      if (status) {
        this.alertOptions = {
          show: true,
          color: 'success',
          title: title
        }
      } else {
        this.alertOptions = {
          show: true,
          color: 'error',
          title: title
        }
      }
    },
    showUpcomingVolunteeringDialog(item) {
      this.selectedUpcomingVolunteering = item
      this.isUpcomingVolunteeringDialogOpen = true
    }
  }
}
</script>
