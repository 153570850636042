var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('background-check-notify',{attrs:{"isOpen":_vm.isBackgroundCheck,"charity-name":_vm.selectedCharityName},on:{"confirmRequest":_vm.applyForVolunteering,"closeDialog":function($event){_vm.isBackgroundCheck = false}}}),_c('v-alert',{staticClass:"rounded-xl ml-5 mr-5",attrs:{"color":_vm.alertOptions.color,"dark":"","dense":"","dismissible":"","icon":"mdi-check-circle-outline","prominent":"","transition":"scale-transition","width":"98%"},model:{value:(_vm.alertOptions.show),callback:function ($$v) {_vm.$set(_vm.alertOptions, "show", $$v)},expression:"alertOptions.show"}},[_c('h2',{class:{'mt-2' : _vm.alertOptions.message}},[_vm._v(_vm._s(_vm.alertOptions.title))]),(_vm.alertOptions.message)?_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.alertOptions.message))]):_vm._e()]),(_vm.isModuleActive('volunteering'))?_c('v-card',{staticClass:"ma-5 rounded-lg",attrs:{"elevation":"3","max-width":"100%"}},[_c('v-data-table',{staticClass:"mx-6 mt-6",attrs:{"footer-props":{
          disablePagination: _vm.loadingOpportunities,
          disableItemsPerPage: _vm.loadingOpportunities
        },"headers":_vm.opportunitiesHeaders,"items":_vm.opportunities,"loading":_vm.loadingOpportunities,"options":_vm.options,"server-items-length":_vm.totalOpportunities},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.sections.open_volunteer_opportunities || 'Open Volunteer Opportunities')+" ")])],1)]},proxy:true},{key:"item.charityName",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-decoration-underline showPointer",on:{"click":function($event){return _vm.goToCharityProfile(item)}}},[_vm._v(" "+_vm._s(item.charityName)+" ")])]}},{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.timeStart)+" - "+_vm._s(item.timeEnd)+" ")]}},{key:"item.spots",fn:function(ref){
        var item = ref.item;
return [_c('div',{class:item.color + '--text'},[_vm._v(" "+_vm._s(item.availableSpots)+" / "+_vm._s(item.volunteersRequired)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{staticClass:"d-flex flex-row align-center justify-start"},[_c('v-btn',{staticClass:"px-2 ma-4 font-weight-bold white--text text-capitalize apply-btn",attrs:{"loading":_vm.loadingConfirm && item.id === _vm.selectedCharity.id,"color":item.requested ? 'error' : 'primary',"small":""},on:{"click":function($event){return _vm.handler(item)}}},[(!item.requested)?_c('span',{staticClass:"px-2"},[_vm._v(" Apply ")]):_c('span',{staticClass:"mr-1 px-1"},[_vm._v(" Cancel ")])]),_c('v-btn',{staticClass:"outlined font-weight-bold white--text text-capitalize",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.openRequestInfoModal(item)}}},[_vm._v(" + Info ")])],1)]}}],null,false,2935473529)})],1):_vm._e(),_c('insufficient-hours-dialog',{attrs:{"isOpen":_vm.isInsufficientHoursDialogOpen},on:{"closeDialog":_vm.closeDialog}}),_c('opportunity-info-dialog',{attrs:{"isOpen":_vm.isInfoDialogOpen,"selectedCharity":_vm.selectedCharity},on:{"closeDialog":function($event){_vm.isInfoDialogOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }